<template>
  <div>
    <ModalBase
      :title="
        title
          ? title
          : 'Registre sua chave para receber e enviar transferências pix'
      "
      :hasFooter="false"
      size="modal-md"
      :id="id"
    >
      <div slot="content">
        <div v-if="page == 'keyselection'" class="col-12 col-w-full">
          <div
            v-for="(keyType, name) in keys"
            :key="name"
            class="material-radio mb-4"
          >
            <WhiteLabel v-if="keyType.available || keyType.allwaysAvailable">
              <a
                href="#"
                class="radio-selector d-flex mb-1"
                @click="selectKeyType(keyType, name)"
              >
                <i :class="`${keyType.icon} fa-2x mr-3`" aria-hidden="true"></i>
                <h4 class="radio-selector form-check-label lbl-filter">
                  {{ keyType.title }}
                </h4>
              </a>
              <p v-if="keyType.value">{{ keyType.value }}</p>
              <p v-if="keyType.description">{{ keyType.description }}</p>
            </WhiteLabel>
          </div>
        </div>

        <div v-if="page == 'cpf'" class="col-12 col-w-full">
          <p class="col-12 col-w-full">
            Confirme seu CPF para receber transferências Pix.
          </p>
          <div class="input-group mb-1 col-12 col-w-full col-lg-6 mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i :class="`${keys.cpf.icon} fa-md`" aria-hidden="true" />
              </span>
            </div>
            <input
              v-model="keys.cpf.value"
              disabled
              type="text"
              class="form-control"
              v-mask="['###.####.###-##']"
            />
          </div>
          <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
            <button
              type="submit"
              class="btn btn-md btn-orange col-12"
              @click="registerCpfKey()"
            >
              Registrar
            </button>
          </WhiteLabel>
        </div>

        <div v-if="page == 'cnpj'" class="col-12 col-w-full">
          <p class="col-12 col-w-full">
            Confirme seu CNPJ para receber transferências Pix.
          </p>
          <div class="input-group mb-1 col-12 col-w-full col-lg-7 mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i :class="`${keys.cnpj.icon} fa-md`" aria-hidden="true" />
              </span>
            </div>
            <input
              v-model="keys.cnpj.value"
              disabled
              type="text"
              class="form-control"
              v-mask="['##.###.###/####-##']"
            />
          </div>

          <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
            <button
              type="submit"
              class="btn btn-md btn-orange col-12"
              @click="registerCnpjKey()"
            >
              Registrar
            </button>
          </WhiteLabel>
        </div>

        <div v-if="page == 'mobile'" class="form-group col-12 col-w-full">
          <p class="col-12 col-w-full">
            Informe o celular que você quer usar para receber ou enviar
            transferências Pix.
          </p>
          <form action="#" @submit.prevent="mobileRequestAuth()">
            <div class="col-12 col-w-full col-lg-6 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i
                      :class="`${keys.mobile.icon} fa-md`"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <input
                  v-model="keys.mobile.value"
                  required
                  type="text"
                  class="form-control"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  placeholder="Celular"
                  name="mobile"
                />
              </div>

              <small class="text-danger" v-if="!$v.keys.mobile.value.required">
                Campo obrigatório
              </small>
              <small class="text-danger" v-if="!$v.keys.mobile.value.phone">
                O celular está invalido
              </small>
            </div>
            <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
              <button type="submit" class="btn btn-md btn-orange col-12">
                Continuar
              </button>
            </WhiteLabel>
          </form>
        </div>

        <div v-if="page == 'mobileAuth'" class="col-12 col-w-full">
          <p class="col-12 col-w-full">
            Insira o código enviado via SMS para
            {{ keys.mobile.value }}
          </p>
          <form action="#" @submit.prevent="registerMobileKey()">
            <div class="form-group col-12 col-w-full col-lg-6 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-lock fa-md" aria-hidden="true" />
                  </span>
                </div>
                <input
                  v-model="keys.mobile.auth"
                  required
                  type="text"
                  class="form-control"
                  name="mobileAuth"
                  v-mask="'######'"
                />
              </div>
              <small class="text-danger" v-if="!$v.keys.mobile.auth.minLength">
                Código deve ter 6 números
              </small>
            </div>
            <p v-if="authCodeTimer > 0" class="col-12 col-w-full">
              Você poderá enviar um novo código em
              <b>{{ authCodeTimer }}</b> segundos...
            </p>
            <WhiteLabel class="mb-4 col-12 col-w-full">
              <a
                v-if="!(authCodeTimer > 0)"
                href="#"
                class="ml-auto mt-1 mr-4"
                @click.prevent="mobileRequestAuth()"
              >
                Enviar novo código
              </a>
            </WhiteLabel>

            <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
              <button type="submit" class="btn btn-md btn-orange col-12">
                Registrar
              </button>
            </WhiteLabel>
          </form>
        </div>

        <div v-if="page == 'email'" class="col-12 col-w-full">
          <p class="col-12 col-w-full">
            Informe o e-mail que você quer usar para receber ou enviar
            transferências Pix.
          </p>
          <form action="#" @submit.prevent="emailRequestAuth()">
            <div class="form-group col-12 col-w-full col-lg-8 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i :class="`${keys.email.icon} fa-md`" aria-hidden="true" />
                  </span>
                </div>
                <input
                  v-model="keys.email.value"
                  required
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  name="email"
                />
              </div>
              <small class="text-danger" v-if="!$v.keys.email.value.required">
                Campo obrigatório
              </small>
              <small class="text-danger" v-if="!$v.keys.email.value.email">
                Email inválido
              </small>
            </div>

            <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
              <button type="submit" class="btn btn-md btn-orange col-12">
                Continuar
              </button>
            </WhiteLabel>
          </form>
        </div>

        <div v-if="page == 'emailAuth'" class="col-12 col-w-full">
          <p class="col-12 col-w-full">
            Insira o código enviado para {{ keys.email.value }}
          </p>
          <form action="#" @submit.prevent="registerEmailKey()">
            <div class="form-group col-12 col-w-full col-lg-6 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-lock fa-md" aria-hidden="true" />
                  </span>
                </div>
                <input
                  v-model="keys.email.auth"
                  required
                  type="text"
                  class="form-control"
                  name="emailAuth"
                />
              </div>
              <small class="text-danger" v-if="!$v.keys.email.auth.minLength">
                Código deve ter 6 caracteres
              </small>
            </div>
            <p v-if="authCodeTimer > 0" class="col-12 col-w-full">
              Você poderá enviar um novo código em
              <b>{{ authCodeTimer }}</b> segundos...
            </p>
            <WhiteLabel class="mb-4">
              <a
                v-if="!(authCodeTimer > 0)"
                href="#"
                class="ml-auto mt-1 mr-4 col-12 col-w-full"
                @click.prevent="mobileRequestAuth()"
              >
                Enviar novo código
              </a>
            </WhiteLabel>

            <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
              <button type="submit" class="btn btn-md btn-orange col-12">
                Registrar
              </button>
            </WhiteLabel>
          </form>
        </div>

        <div v-if="page == 'aleatory'" class="col-12 col-w-full">
          <p class="col-12 col-w-full">
            Com a chave aleatória, você gera códigos QR para receber
            transferências Pix sem precisar informar seus dados.
          </p>
          <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
            <button
              type="submit"
              class="btn btn-md btn-orange col-12"
              @click="registerAleatoryKey()"
            >
              Confirmar
            </button>
          </WhiteLabel>
        </div>

        <div v-if="page == 'keyRegistered'" class="col-12 col-w-full mb-4">
          <div class="col-12 col-w-full">
            <p>{{ confirmation.text }}</p>
            <h3 v-if="false">{{ type.title }}</h3>
            <h3>{{ confirmation.key }}</h3>

            <div
              v-if="type.value == 'email' || type.value == 'mobile'"
              class="mt-5"
            >
              <hr />
              <h4>Sobre sua chave</h4>
              <p>
                Ao informar sua chave para receber uma transferência, a pessoa
                terá acesso aos seguintes dados:
              </p>
              <ul>
                <li>Seu nome completo</li>
                <li>
                  Apenas alguns dígitos do seu
                  {{ keys.cnpj.available != null ? 'CNPJ' : 'CPF' }}
                </li>
              </ul>
              <p>
                Você poderá registrar até
                {{ keys.cnpj.available != null ? '20' : '5' }} chaves em seu
                {{ keys.cnpj.available != null ? 'CNPJ' : 'CPF' }} na Pagcerto.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
// component imports
import ModalBase from '@/components/shared/ModalBase';
import WhiteLabel from '@/components/shared/WhiteLabel';

// validation imports
import { required, minLength, email } from 'vuelidate/lib/validators';
import { phone } from '@/validations/phone';

// api imports
import AuthCodeApi from '@/services/v2/AuthCodeApi';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';

// error imports
import PixCreationError from '@/errors/PixCreationError';
import PixSugestionsError from '@/errors/PixSugestionsError';
import swal from 'sweetalert2';
import DeviceApi from '@/services/DeviceApi';
import FingerPrintApi from '@/services/FingerPrintApi';
import AccountApi from '@/services/AccountApi';
import { Presets } from '@/models/Presets';

export default {
  name: 'KeyRegistrationModal',
  components: {
    ModalBase,
    WhiteLabel,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    title: '',
    deviceId: null,
    requestId: null,
    preset: new Presets(),
    keys: {
      cpf: {
        title: 'CPF',
        description: '',
        value: '',
        icon: 'fa fa-id-card-o',
      },
      cnpj: {
        title: 'CNPJ',
        description: '',
        icon: 'fa fa-id-card-o',
      },
      mobile: {
        title: 'Celular',
        description: '',
        value: '',
        icon: 'fa fa-phone',
        allwaysAvailable: true,
      },
      email: {
        title: 'Email',
        description: '',
        value: '',
        icon: 'fa fa-envelope',
        allwaysAvailable: true,
      },
      aleatory: {
        title: 'Chave aleatória',
        description: '',
        value: '',
        icon: 'fa fa-key',
        allwaysAvailable: true,
      },
    },
    type: {},
    page: 'keyselection',
    confirmation: {
      text: '',
      key: '',
    },
    authCodeTimer: 20,
  }),

  watch: {
    authCodeTimer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.authCodeTimer--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  async mounted() {
    await this.getPreset();
    await this.checkDeviceAuthorization();
    this.$bus.$on('cleanInput', (result) => {
      this.cleanInput();
    });

    await this.loadSugestions();
  },

  methods: {
    async getPreset() {
      var accountApi = new AccountApi();

      this.preset = await accountApi.presets();
    },

    async checkDeviceAuthorization() {
      if (this.preset == null) await this.getPreset();

      this.isNaturalPerson = this.preset.holder.company == null;

      if (this.isNaturalPerson) {
        const fingerPrintApi = new FingerPrintApi();
        await fingerPrintApi.verifyFingerPrintExpiration(this.preset.holder.id);
        const { fingerPrint } = JSON.parse(localStorage.getItem('fingerPrint'));
        const deviceApi = new DeviceApi();
        const response = await deviceApi.getDevice(fingerPrint.visitorId);
        if (response.status == 200) {
          this.deviceId = response.data.id;
          this.requestId = fingerPrint.requestId;
        }
      }
    },

    async loadSuggestionsAgain() {
      await this.loadSugestions();
    },

    cleanInput() {
      this.type = {};
      this.keys.email.auth = '';
      this.keys.mobile.auth = '';
      this.page = 'keyselection';
      this.title = '';
    },

    async loadSugestions() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      const result = await api.pix.getSugestions();

      if (result.status == 200) {
        result.data.keys.forEach((suggestedKey) => {
          this.keys[suggestedKey.type].description = suggestedKey.description;
          this.keys[suggestedKey.type].available = suggestedKey.available;

          if (suggestedKey.available)
            this.keys[suggestedKey.type].value = suggestedKey.mask;
        });
      } else if (result.status == 422) {
        const modelError = new PixSugestionsError({ error: result.data.error });
        this.$alert.error('Ops', modelError.getMessage());
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
      }
      this.$bus.$emit('spinner-stop');
    },

    selectKeyType(keyType, name) {
      this.page = name;
      this.title = `Registrar ${keyType.title}`;
      this.type.value = name;
      this.type.title = keyType.title;
    },

    async registerCpfKey() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();

      const model = {
        type: this.type.value,
        key: this.keys.cpf.value,
      };

      const result = await api.pix.registerPixKey(
        model,
        null,
        this.deviceId,
        this.requestId
      );
      this.$bus.$emit('spinner-stop');

      const confirmationText =
        'Receba e transferências pelo Pix usando apenas seu CPF.';
      this.finalize(result, confirmationText, model.key);
    },

    async registerCnpjKey() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();

      const model = {
        type: this.type.value,
        key: this.keys.cnpj.value,
      };

      const result = await api.pix.registerPixKey(
        model,
        null,
        this.deviceId,
        this.requestId
      );
      this.$bus.$emit('spinner-stop');

      const confirmationText =
        'Receba e transferências pelo Pix usando apenas seu CNPJ.';
      this.finalize(result, confirmationText, model.key);
    },

    async mobileRequestAuth() {
      this.$bus.$emit('spinner-run');
      const api = new AuthCodeApi();
      const parameters = {
        type: 'pix-register-key',
        mobile: true,
        destination: this.keys.mobile.value,
      };
      const result = await api.getAuthCode(parameters);
      this.$bus.$emit('spinner-stop');

      if (result.status == 204) {
        this.page = 'mobileAuth';
        this.authCodeTimer = 30;
      } else {
        this.$alert.error(
          'Ops',
          'Não foi possível solicitar o código de autenticação'
        );
      }
    },

    async registerMobileKey() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();

      const model = {
        type: this.type.value,
        key: this.keys.mobile.value,
      };

      const result = await api.pix.registerPixKey(
        model,
        this.keys.mobile.auth,
        this.deviceId,
        this.requestId
      );
      this.$bus.$emit('spinner-stop');

      const confirmationText =
        'Receba e envie transferências pelo Pix usando apenas seu numero celular.';
      this.finalize(result, confirmationText, model.key);
    },

    async emailRequestAuth() {
      this.$bus.$emit('spinner-run');
      const api = new AuthCodeApi();
      const parameters = {
        type: 'pix-register-key',
        mobile: false,
        destination: this.keys.email.value,
      };
      const result = await api.getAuthCode(parameters);
      this.$bus.$emit('spinner-stop');

      if (result.status == 204) {
        this.page = 'emailAuth';
        this.authCodeTimer = 30;
      } else {
        this.$alert.error(
          'Ops',
          'Não foi possível solicitar o código de autenticação'
        );
      }
    },

    async registerEmailKey() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();

      const model = {
        type: this.type.value,
        key: this.keys.email.value,
      };

      const result = await api.pix.registerPixKey(
        model,
        this.keys.email.auth,
        this.deviceId,
        this.requestId
      );
      this.$bus.$emit('spinner-stop');

      const confirmationText =
        'Receba e envie transferências pelo Pix usando apenas seu endereço de e-mail.';
      this.finalize(result, confirmationText, model.key);
    },

    async registerAleatoryKey() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();

      const model = {
        type: this.type.value,
        key: null,
      };

      const result = await api.pix.registerPixKey(
        model,
        null,
        this.deviceId,
        this.requestId
      );
      this.$bus.$emit('spinner-stop');

      const confirmationText =
        'Receba transferências pelo Pix usando sua chave aleatória..';
      this.finalize(
        result,
        confirmationText,
        result.data.key ? result.data.key : ''
      );
    },

    finalize(result, text, key) {
      $(`#${this.id}`).modal('hide');
      if (result.status == 200) {
        if (result.data.confirmed) {
          this.$alert.info(
            `Pix registrado a partir ${
              this.type.value != 'aleatory' ? 'do seu' : 'da sua'
            } ${this.type.title}!`,
            ''
          );

          this.confirmation.text = text;
          this.confirmation.key = key;

          this.title = `${this.type.title} Registrad${
            this.type.value != 'aleatory' ? 'o' : 'a'
          }`;
          this.page = 'keyRegistered';

          this.$emit('key-registered');
        } else {
          const error = result.data.error;
          if (
            error.message === 'EXISTENTE_MESMO_DONO_E_PSP' ||
            error.message === 'EXISTENTE_OUTRO_DONO'
          ) {
            const modelError = new PixCreationError({ error: error.message });
            swal
              .fire({
                title: 'Ops',
                text: modelError.getMessage(),
                type: 'error',
                confirmButtonText: 'Reivindicar posse',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                showCloseButton: true,
              })
              .then((result) => {
                if (result.value) {
                  this.$emit('claim-requested', {
                    key: key,
                    keyType: this.type.value,
                  });
                }
                this.cleanInput();
              });

            return;
          }

          this.$alert.error('Ops', error.message);
          this.cleanInput();
        }
      } else if (result.status == 403) {
        this.$alert.error(
          'Ops',
          'Você não tem as permissões necessárias para cadastrar uma chave PIX'
        );
        this.cleanInput();
      } else if (result.status == 422) {
        const modelError = new PixCreationError({ error: result.data.error });
        this.$alert.error('Ops', modelError.getMessage());
        this.cleanInput();
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.cleanInput();
      }
    },
  },

  validations: {
    type: {
      required,
    },

    keys: {
      mobile: {
        value: {
          required,
          phone,
        },
        auth: {
          minLength: minLength(6),
        },
      },
      email: {
        value: {
          required,
          email,
        },
        auth: {
          minLength: minLength(6),
        },
      },
    },
  },
};
</script>

<style scoped>
.no-padding {
  padding: 0;
}
</style>
