<template>
  <div class="ted-container">
    <section class="mb-4">
      <div class="d-flex form-group">
        <div class="form-group col-1 mb-4">
          <i class="icon icon-small transfer gray"></i>
        </div>
        <div class="form-group col-11 mb-4">
          <label class="small-label">Valor</label>
          <label class="price-label">
            {{
              destination.amount
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </label>
        </div>
      </div>
      <div class="d-flex mt-2 form-group">
        <div class="form-group offset-1 col-12">
          <label class="small-label">Pagar em</label>
          <datepicker
            v-model="event.payDate"
            :inline="false"
            @input="checkDate()"
            input-class="border-0 text-muted pl-0 col-md-5 col-6 col-sm-5 col-lg-5"
            :calendar-button="true"
            calendar-button-icon="icon-calendar"
            language="pt-br"
            format="dd/MM/yyyy"
            placeholder="00/00/0000"
          />
          <small class="text-danger" v-if="!this.validateDate">
            Data menor do que a atual.
          </small>
        </div>
      </div>

      <div class="d-flex mt-2 form-group">
        <div class="form-group col-1">
          <i class="icon-small icon-wallet gray" />
        </div>
        <div class="form-group col-11">
          <label class="small-label">Destino</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">{{
            destination.details.account.name
          }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">{{
            destination.details.account.taxDocument
          }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">
            <strong>Agência:</strong>
          </label>
          {{ destination.details.account.branch }}
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">
            <strong>
              Conta
              {{ getAccountType(destination.details.account.type) }}:
            </strong>
          </label>
          {{ destination.details.account.number }}
        </div>
      </div>

      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">{{
            destination.details.account.institutionName
          }}</label>
        </div>
      </div>
      <WhiteLabel class="mt-4 offset-8 col-4 col-w-full">
        <button @click="confirm()" class="btn btn-md btn-orange col-12">
          Confirmar
        </button>
      </WhiteLabel>
    </section>
  </div>
</template>

<script>
// component imports
import WhiteLabel from '@/components/shared/WhiteLabel';
import Datepicker from 'vuejs-datepicker';
import Layout from '../../../theme/Layout.vue';

export default {
  name: 'ConfirmPixTransferModalSection',

  components: {
    WhiteLabel,
    Datepicker,
  },

  props: {
    destination: {
      type: Object,
      required: true,
      default: () => {
        return {
          label: '',
          amount: '',
          prefix: '',
          id: '',
          icon: '',
          details: {
            account: {},
          },
        };
      },
    },
  },

  data: () => ({
    validateDate: true,
    event: {
      schedule: false,
      payDate: null,
    },
  }),
  async mounted() {
    const currentDate = Date.now();
    this.event.payDate = new Date(currentDate);
  },

  methods: {
    getAccountType(accountType) {
      switch (accountType) {
        case 'checking':
          return 'corrente';
        case 'savings':
          return 'poupança';
        case 'salary':
          return 'salário';

        default:
          break;
      }
    },
    equalsDate(date) {
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = date.getFullYear();

      return new Date(`${year}-${month}-${day}`);
    },

    confirm() {
      if (!this.checkDate()) {
        this.$alert.error(
          'Ops',
          'A data do agendamento não pode ser menor do que a data atual.'
        );
        return;
      }

      this.$emit('confirm', this.event);
    },

    checkDate() {
      const currentDate = Date.now();
      let date = new Date(currentDate);
      let currentDateFormatted = this.equalsDate(date);
      let scheduledDate = this.equalsDate(this.event.payDate);

      if (scheduledDate < currentDateFormatted) {
        this.validateDate = false;

        return this.validateDate;
      } else if (scheduledDate > currentDateFormatted) {
        this.event.schedule = true;
      }

      this.validateDate = true;
      return this.validateDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.ted-container {
  padding: 1rem;
}
.input-money {
  font-size: 48px;
  max-width: 100%;
  border: none;
  outline: none;
  font-weight: lighter;
  border-bottom: solid thin;
  border-color: #a1a4b1;
}

.mt-lg {
  margin-top: 80px;
}
.small-label {
  display: block;
  text-transform: uppercase;
  color: #7d8287;
  font-size: 12px;
}
.price-label {
  display: block;
  font-size: 30px;
  color: #7d8287;
}
.small-price-label {
  display: block;
  color: #7d8287;

  &--inline {
    display: inline;
  }
}
.icon-small {
  width: 20px;
  height: 20px;
}
</style>
